<template>
    <div class="sticky-component px-4 mt-0" v-if="!this.isClose">
        <div class="row pt-2 pb-1">

            <a class="col-1 d-flex pr-0 pt-3" @click="close">
                <font-awesome-icon icon="close" style="color: grey" />
            </a>

            <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex pr-0">
                <img style="
                    width: 40px;
                    height: 40px;
                    object-fit: scale-down;
                    background-color: whitesmoke;" class="display-picture img-fluid rounded-circle"
                    src="@/assets/kamsia.png" alt="" />
            </div>

            <div class="col-5 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex pr-0 pl-2 carousel slide"
                id="carouselExampleControlsText" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item overflow-hidden text-truncate" :key="currentSlideIndex"
                        :class="carousel_text[currentSlideIndex].is_main == '1' ? 'active' : carousel_text[currentSlideIndex].is_main == '2' ? 'active' : carousel_text[currentSlideIndex].is_main == '3' ? 'active' : carousel_text[currentSlideIndex].is_main == '4' ? 'active' : ''">
                        <b>{{ carousel_text[currentSlideIndex].first_text }}</b>
                        <p class="m-0 lh-1">{{ carousel_text[currentSlideIndex].second_text }}</p>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-target="#carouselExampleControlsText"
                    data-slide="prev">
                    <span class="carousel-control-prev-icon example-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-target="#carouselExampleControlsText"
                    data-slide="next">
                    <span class="carousel-control-next-icon example-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </button>
            </div>

            <div class="col-4 text-right pl-0">
                <button class="mt-1 btn py-1 px-3 btn-kamsia text-white rounded-20" @click="redirectToApp">
                    Buka App
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'StickyComponent',
    data() {
        return {
            // isClose: false,
            isClose: true,
            loading: false,
            currentSlideIndex: 0,
            carousel_text: [
                { first_text: 'Buka di app untuk', second_text: 'dapatkan kelebihannya!', is_main: '1' },
                { first_text: 'Bisa lebih tau', second_text: 'menu dan harga resto!', is_main: '2' },
                { first_text: 'Dapatkan fitur notif', second_text: 'promo di app!', is_main: '3' },
                { first_text: 'Lebih mudah akses', second_text: 'ambil ditempat!', is_main: '4' }
            ]
        };
    },
    mounted() {
        setInterval(() => {
            this.changeSlide(1); // Move to the next slide
        }, 4000);
        this.isClose = !((localStorage.getItem('device_id') ?? 'null') == 'null' && (localStorage.getItem("is_ios") ?? 'null') == 'null');
        // this.isClose = !(localStorage.getItem("close_openApp") == false || localStorage.getItem('device_id') == 'null');
        if (window.location.href.includes('is_ios') == true) {
            this.isClose = true;
        }
    },
    methods: {
        changeSlide(step) {
            this.currentSlideIndex = (this.currentSlideIndex + step) % this.carousel_text.length;
            if (this.currentSlideIndex < 0) {
                this.currentSlideIndex = this.carousel_text.length - 1;
            }
        },
        close() {
            this.isClose = !this.isClose;
            // localStorage.setItem("close_openApp", this.isClose);
        },
        redirectToApp() {
            if (this.loading === false) {
                if ((window.location.href).includes('qr')) {

                    const api_url = this.mainUrl + "/api/links";
                    this.loading = true;

                    let form = new FormData();
                    // 253Dca4deab5-ceda-40a9-b888-07d065097432 (IRG)
                    // c0586041-e813-4267-9645-928bf5914a7c (KAM)
                    form.append("table", (window.location.href).split('qr=')[1]);
                    form.append("app_id", "KAM");

                    axios
                        .post(api_url, form)
                        .then((res) => {
                            console.log(res);
                            console.log(res.data.url);
                            if (res["status"] == 200) {
                                this.loading = false;
                                try {
                                    window.location = "kam://kam5ia.com//?url=" + res.data.url + '&qr=' + (window.location.href).split('qr=')[1]
                                    setTimeout(function () {
                                        if ((localStorage.getItem('is_ios', false) ?? 'null') != 'null' || (!!navigator && /iPad|iPhone|iPod/.test(navigator.userAgent))) {
                                            window.location = "https://apps.apple.com/id/app/kamsia/id6463642371";
                                        } else {
                                            window.location = "https://play.google.com/store/apps/details?id=com.devus.kam5ia";
                                        }
                                    }, 2000);
                                } catch (error) {
                                    console.error("Navigation failed:", error);
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err.response.data.message);
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                    if ((window.location.href).includes('localhost')) {
                        if ((window.location.href).includes('resto-detail')) {
                            window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/" + (window.location.href).replaceAll('http://localhost:8080/', '').replaceAll('http://localhost:8081/', '')
                        } else {
                            window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/resto"
                        }
                    } else {
                        if ((window.location.href).includes('resto-detail')) {
                            window.location = "kam://kam5ia.com//?url=" + window.location.href
                        } else {
                            window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/resto"
                        }
                    }
                    try {
                        setTimeout(function () {
                            if ((localStorage.getItem('is_ios', false) ?? 'null') != 'null' || (!!navigator && /iPad|iPhone|iPod/.test(navigator.userAgent))) {
                                window.location = "https://apps.apple.com/id/app/kamsia/id6463642371";
                            } else {
                                window.location = "https://play.google.com/store/apps/details?id=com.devus.kam5ia";
                            }
                        }, 2000);
                    } catch (error) {
                        console.error("Navigation failed:", error);
                    }
                }
            }
        }
    }
};

</script>

<style scoped>
.sticky-component {
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: #ffffff;
}
</style>