<template>
    <div class="body">
        <div v-if="isLoading" class="loading-container">
            <img src="@/assets/loading.gif" alt="Loading" class="loading-icon" />
        </div>
        <section class="section">
            <div class="container">
                <div class="d-flex w-100 mt-4 mb-3 px-2">
                    <a @click="goBack" class="text-decoration-none text-weight-bold text-dark text-1rem">
                        <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"
                            class="icon-xl mr-3"></font-awesome-icon>
                        Isi data {{ determineType(isChoice) }}
                    </a>
                </div>
                <Form class="text-left" @submit="handleAdd" :validation-schema="schema" v-slot="{ errors }">
                    <div v-if="!successful">
                        <!-- Penting
                        <div class="form-group" v-if="this.$route.params.item.length == 0">
                            <label class="form-label">Data Sebagai</label>
                            <div class="selectgroup w-100">
                                <label class="selectgroup-item">
                                    <Field type="radio" name="is_choice" :value="true" class="selectgroup-input"
                                        v-model="isChoice" />
                                    <span class="selectgroup-button font-weight-normal">
                                        Pilihan
                                    </span>
                                </label>
                                <label class="selectgroup-item">
                                    <Field type="radio" name="is_choice" :value="false" class="selectgroup-input"
                                        v-model="isChoice" />
                                    <span class="selectgroup-button font-weight-normal">
                                        Add On
                                    </span>
                                </label>
                            </div>
                            <ErrorMessage name="is_choice" class="invalid-feedback" as="div" style="display: block;" />
                        </div> -->
                        <div class="my-2" v-if="isChoice">
                            <label class="text-dark" style="font-weight: 600; font-size: 12px; letter-spacing: 0.5px"
                                for="photo">Foto {{ determineType(isChoice) }}</label>
                            <div class="wrapper">
                                <div class="photo_submit-container">
                                    <div class="photo_submit-container">
                                        <label class="photo_submit js-photo_submit1" ref="uploadBtn">
                                            <Field ref="fileInput" class="photo_submit-input js-photo_submit-input"
                                                type="file" accept="image/*" name="image" @change="uploadImage" />
                                            <span class="photo_submit-plus"></span>
                                            <span class="photo_submit-delete" @click="deleteBtn"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label v-if="errors.image" class="mt-1" style="color: #dc3545; font-size: 0.875em">{{
                            errors.image }}</label>
                        <!-- <div class="form-group">
                            <label class="custom-switch mt-2">
                                <Field v-model="isChoice" name="isChoice" type="checkbox" class="custom-switch-input"
                                    :value="true" />
                                <span class="custom-switch-indicator" ref="checkboxAvailable"></span>
                                <span class="custom-switch-description">{{ determineType(isChoice) }}</span>
                            </label>
                        </div> -->
                        <div class="form-group">
                            <label class="text-dark" for="name">Nama {{ determineType(isChoice) }}</label>
                            <Field id="name" name="name" type="text" class="form-control"
                                :class="{ 'is-invalid': errors.name }" v-model="this.items.name" />
                            <ErrorMessage name="name" class="invalid-feedback" />
                        </div>
                        <div class="form-group">
                            <label class="text-dark" for="price">Harga {{ determineType(isChoice) }} ( Pastikan harga {{
                                determineType(isChoice) }} sudah termasuk pajak
                                )</label>
                            <label style="color: #f9b93d;" for="price">*Jika harga tidak berbeda maka isi dengan nominal 0</label>
                            <Field id="price" name="price" type="number" class="form-control"
                                :class="{ 'is-invalid': errors.price }" v-model="this.items.price" />
                            <ErrorMessage name="price" class="invalid-feedback" />
                        </div>
                        <div class="form-group" v-if="isChoice && category.length > 0">
                            <label class="form-label">Pilih Kategori</label>
                            <div class="selectgroup selectgroup-pills">
                                <label class="selectgroup-item" v-for="(item, itemIndex) in category" :key="itemIndex">
                                    <Field name="category_id" type="radio" :value="item.id" class="selectgroup-input"
                                        :class="{ 'is-invalid': errors.category_id }" v-model="this.selected_category_id" />
                                    <span class="selectgroup-button selectgroup-button-icon px-3">{{
                                        item.name
                                    }}</span>
                                </label>
                            </div>
                            <label v-if="errors.category_id" class="mt-1" style="font-size: 0.875em; color: #dc3545">{{
                                errors.category_id }}
                                !</label>
                        </div>
                        <div class="form-group">
                            <label class="text-dark" for="description">Deskripsi {{ determineType(isChoice) }}</label>
                            <Field id="description" name="description" type="text" class="form-control"
                                :class="{ 'is-invalid': errors.description }" v-model="this.items.description" />
                            <ErrorMessage name="description" class="invalid-feedback" />
                        </div>
                        <div class="w-100 mt-3">
                            <div class="row">
                                <div class="col-12">
                                    <button :disabled="loading" type="submit"
                                        class="btn btn-lg text-1rem btn-block bg-kamsia text-white rounded-20">
                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                        Simpan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </section>
    </div>
</template>
  
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default {
    name: "ChoiceOwner",
    props: ["item"],
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {

        const MAX_FILE_SIZE = 1048576; //1MB
        const validFileExtensions = { image: ["jpg", "png", "jpeg"] };

        function isValidFileType(fileName, fileType) {
            return (
                fileName && validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
            );
        }

        const schema = yup.object().shape({
            image: yup.mixed().when({
                is: () => {
                    if (this.isChoice === true && this.items.img === null) {
                        return true;
                    } else return false;
                },
                then: (schema) => schema
                    .test("is-valid-type", "Foto harus diisi (jpg, jpeg atau png)", (value) =>
                        isValidFileType(value && value.name.toLowerCase(), "image")
                    )
                    .test(
                        "is-valid-size",
                        "Maksimal ukuran gambar dibawah 1MB",
                        (value) => value && value.size <= MAX_FILE_SIZE
                    ),
            }),
            is_choice: yup.string().when({
                is: () => {
                    return this.isChoice == null;
                },
                then: (schema) => schema.required("Pilih sebagai Pilihan atau Add On"),
            }),
            name: yup.string().required("Nama harus diisi"),
            price: yup
                .number()
                .integer("Harga harus berupa angka bulat")
                .typeError("Harga harus berupa angka")
                .required("Harga harus diisi"),
            category_id: yup.string().when({
                is: () => {
                    if (this.isChoice === true && this.selected_category_id === null) {
                        return true;
                    } else return false;
                },
                then: (schema) => schema.required("Kategori harus dipilih"),
            }),
            description: yup.string().required("Deskripsi harus diisi"),
        });

        return {
            isLoading: true,
            isChoice: true,
            category: [],
            selected_category_id: null,
            items: [],
            image: null,
            fileInput: null,
            uploadBtn: null,
            schema
        };
    },
    created() {
        if (localStorage.getItem("token") === null) {
            this.$router.push("/login");
        }
        window.addEventListener('popstate', this.goBack);
    },
    mounted() {
        window.checkFunctionExists = () => {
            return typeof this.goBackFlutter === 'function';
        };

        window.goBackFlutter = () => {
            this.goBackFlutter();
        };

        // const updatedQuery = { ...this.$route.query };
        this.isChoice = (this.$route.query.is_choice.toString() == 'pilihan') ? true : false;
        // updatedQuery.is_choice = (this.$route.query.is_choice.toString() == 'false') ? 'addon' : 'pilihan';
        // this.$router.replace({ query: updatedQuery });

        if (this.$route.params.item.length) {
            this.fetchData();
        } else {
            this.getCategory();
            // this.isLoading = false;
        }
    },
    watch: {
        isChoice: {
            handler() {
                const updatedQuery = { ...this.$route.query };
                updatedQuery.is_choice = (this.isChoice == true) ? 'pilihan' : 'addon';
                this.$router.replace({ query: updatedQuery });
            },
            deep: true,
        },
    },
    methods: {
        goBack() {
            if (this.$route.params.item.length) {
                if (this.$route.query.is_choice == 'true' || this.$route.query.is_choice == 'pilihan') {
                    this.$router.replace("../../choice-list/" + this.$route.params.id + '?is_choice=true');
                } else {
                    this.$router.replace("../../choice-list/" + this.$route.params.id + '?is_choice=false');
                }
            } else {
                if (this.$route.query.is_choice == 'true' || this.$route.query.is_choice == 'pilihan') {
                    this.$router.replace("../choice-list/" + this.$route.params.id + '?is_choice=true');
                } else {
                    this.$router.replace("../choice-list/" + this.$route.params.id + '?is_choice=false');
                }
            }
        },
        getCategory() {
            const token = localStorage.getItem("token");
            axios
                .get(this.mainUrl + "/api/kamsia/v2/owner/category", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    this.category = res.data;
                    if (this.items.img) {
                        this.$refs.uploadBtn.setAttribute(
                            "style",
                            `background-image: url('${this.items.img}');`
                        );
                        this.$refs.uploadBtn.classList.add("photo_submit--image");
                        this.isLoading = false;
                        this.$refs.fileInput.setAttribute("readonly", "readonly");
                    } else {
                        this.isLoading = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // if (err.response.status == 401) {
                    //     this.$router.push("/login");
                    // }
                });
        },
        fetchData() {
            this.isLoading = true;

            let _url = this.mainUrl + "/api/kamsia/v2/owner/item/" + this.$route.params.id + "/choice/show";

            const token = localStorage.getItem("token");
            axios
                .get(_url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        "id": this.$route.params.item,
                        "is_choice": this.$route.query.is_choice,
                    }
                })
                .then((res) => {
                    // console.log("res api");
                    // console.log(this.items.image);
                    this.items = res.data.payload;
                    if (this.items.category_id) {
                        this.selected_category_id = Math.floor(this.items.category_id);
                    }
                    this.isChoice = res.data.payload.is_choice;
                    if (this.isChoice) {
                        this.getCategory();
                    } else {
                        this.isLoading = false;
                    }
                    // this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                });
        },
        uploadImage(e) {
            this.fileInput = e.target;
            this.uploadBtn = this.fileInput.parentNode;
            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadBtn.setAttribute(
                    "style",
                    `background-image: url('${e.target.result}');`
                );
                this.uploadBtn.classList.add("photo_submit--image");
                this.fileInput.setAttribute("readonly", "readonly");
            };

            if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);

            this.image = e.target.files[0];
        },
        deleteBtn(e) {
            this.uploadBtn.removeAttribute("style");
            this.uploadBtn.classList.remove("photo_submit--image");
            e.target.value = "";
            setTimeout(() => {
                this.fileInput.removeAttribute("disabled", "disabled");
            }, 200);
        },
        handleAdd(data) {

            console.log(this.image, ' add');

            let formData = {
                "image": this.image,
                "name": data.name,
                "is_choice": this.isChoice,
                "price": data.price,
                "description": data.description,
                "outlet_id": JSON.parse(localStorage.getItem("outlet")).id,
                "category_id": this.selected_category_id,
                "parent_id": this.items.parent_id ?? null,
                "reference_parent_id": this.items.reference_parent_id ?? null,
                // "_method": "PUT"
            }
            console.log(formData);

            const token = localStorage.getItem("token");

            if (this.$route.params.item.length) {
                formData["_method"] = "PUT";

                let _url = this.mainUrl + "/api/kamsia/v2/owner/item/" + this.$route.params.id + "/choice/" + this.$route.params.item + "/update";

                axios.post(_url, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                }).then((res) => {
                    console.log(res);
                    // this.$router.go(-1);
                    this.$router.replace("../../choice-list/" + this.$route.params.id + "?is_choice=" + this.isChoice);
                }).catch((err) => {
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                    console.log(err);
                });
            } else {

                let _url = this.mainUrl + "/api/kamsia/v2/owner/item/" + this.$route.params.id + "/choice/store";

                axios.post(_url, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                }).then((res) => {
                    console.log(res);
                    this.$router.replace("../choice-list/" + this.$route.params.id + "?is_choice=" + this.isChoice);
                    // this.$router.replace("../choice-list/" + this.$route.params.id + "?is_choice=" + this.isChoice);
                }).catch((err) => {
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                    console.log(err);
                });
            }
        },
        determineType(isChoice) {
            return isChoice ? " Pilihan" : " Add-On"
        }
    }
};
</script>
  
<style scoped>
/* Your scoped styles go here */

.upload-btn {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background-size: cover;
    background-position: center;
}

.selectgroup-button {
    height: 45px;
    line-height: 45px;
}

.selectgroup-input:focus+.selectgroup-button,
.selectgroup-input:checked+.selectgroup-button {
    background-color: #5b3825;
    color: white;
    z-index: 1;
}
</style>
  