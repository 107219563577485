<template>
  <div v-if="!isClose">
    <div class="modal fade show d-block sticky-modal-component" style="background-color: rgba(0, 0, 0, 0.4);"
      ref="isClose" tabindex="-1" role="dialog" id="isClose">
      <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-bottom" role="document">
          <div class="modal-content order-modal">
            <div class="modal-body px-0 pt-3 pb-0" style="max-width: 100vw">
              <div class="container">
                <p class="text-center px-5 pt-2"
                  style="color: #5b3825; font-weight: bolder; font-size: 16px; line-height: 1.2;">Dapatkan kelebihan semua
                  ini hanya melalui aplikasi!</p>
                <div class="row">
                  <div class="col-12">
                    <div class="col-12 pb-1 px-2 text-center">
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-4" style="padding: 0;">
                          <img src="@/assets/kam-promo.png" style="object-fit: contain;" alt="" srcset=""
                            class="image-transaction" />
                          <p class="text-center pt-2"
                            style="color: #5b3825; font-weight: bolder; font-size: 12px; line-height: 1.2;">Dapatkan harga
                            murah dari tempat makan favorit kalian</p>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4" style="padding: 0;">
                          <img src="@/assets/kam-order.png" style="object-fit: contain;" alt="" srcset=""
                            class="image-transaction" />
                          <p class="text-center pt-2"
                            style=" color: #5b3825; font-weight: bolder; font-size: 12px; line-height: 1.2;">Lebih tau
                            lengkap menu beserta harga tempat makan</p>
                        </div>
                        <div class="col-1"></div>
                      </div>
                      <div class="row">
                        <div class="col-1"></div>
                        <div class="col-4" style="padding: 0;">
                          <img src="@/assets/kam-drink.png" style="object-fit: contain;" alt="" srcset=""
                            class="image-transaction" />
                          <p class="text-center pt-2"
                            style="color: #5b3825; font-weight: bolder; font-size: 12px; line-height: 1.2;">Bisa memesan
                            secara take away</p>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4" style="padding: 0;">
                          <img src="@/assets/kam-search.png" style="object-fit: contain;" alt="" srcset=""
                            class="image-transaction" />
                          <p class="text-center pt-2"
                            style="color: #5b3825; font-weight: bolder; font-size: 12px; line-height: 1.2;">Pencarian
                            jenis
                            usaha dan tipe makanan lebih lengkap</p>
                        </div>
                        <div class="col-1"></div>
                      </div>
                      <a class="btn btn-block btn-kamsia text-white rounded-10 my-2"
                        style="padding: 0.25rem 1.5rem; font-size: 12px;" @click="this.redirectToApp()">
                        Buka di Aplikasi
                        <!-- <img style="height: 22px; object-fit: cover" v-if="isBtnLoading" src="@/assets/loading.gif"
                        alt="Loading" class="loading-icon" /> -->
                      </a>
                      <a class="btn btn-block btn-outline-kamsia rounded-10 my-2"
                        style="padding: 0.25rem 1.5rem; font-size: 12px;" @click="this.close()">
                        Tetap di Sini
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'StickyModalComponent',
  data() {
    return {
      // isClose: false,
      isClose: true,
      loading: false,
    };
  },
  mounted() {
    this.isClose = !((localStorage.getItem("close_openApp") ?? false) == false && (localStorage.getItem('device_id') ?? 'null') == 'null' && (localStorage.getItem("is_ios") ?? 'null') == 'null');
    if ((window.location.href).includes('resto-detail') && (window.location.href).includes('qr') || (window.location.href).includes('share')) {
      if ((localStorage.getItem('device_id') ?? 'null') == 'null') {
        this.isClose = false;
      } else {
        this.isClose = true;
      }
    }
    if (window.location.href.includes('is_ios') == true) {
      this.isClose = true;
    }
    if ((window.location.href).includes('group') && (localStorage.getItem('device_id') ?? 'null') == 'null') {
      this.isClose = false
    }
  },
  methods: {
    close() {
      this.isClose = !this.isClose;
      localStorage.setItem("close_openApp", this.isClose);
    },
    redirectToApp() {
      if (this.loading === false) {
        if ((window.location.href).includes('qr')) {

          const api_url = this.mainUrl + "/api/links";
          this.loading = true;

          let form = new FormData();
          // 253Dca4deab5-ceda-40a9-b888-07d065097432 (IRG)
          // c0586041-e813-4267-9645-928bf5914a7c (KAM)
          form.append("table", (window.location.href).split('qr=')[1]);
          form.append("app_id", "KAM");

          axios
            .post(api_url, form)
            .then((res) => {
              console.log(res);
              console.log(res.data.url);
              if (res["status"] == 200) {
                this.loading = false;
                try {
                  window.location = "kam://kam5ia.com//?url=" + res.data.url
                  setTimeout(function () {
                    if ((localStorage.getItem('is_ios', false) ?? 'null') != 'null' || (!!navigator && /iPad|iPhone|iPod/.test(navigator.userAgent))) {
                      window.location = "https://apps.apple.com/id/app/kamsia/id6463642371";
                    } else {
                      window.location = "https://play.google.com/store/apps/details?id=com.devus.kam5ia";
                    }
                  }, 2000);
                } catch (error) {
                  console.error("Navigation failed:", error);
                }
              }
            })
            .catch((err) => {
              console.log(err.response.data.message);
              this.loading = false;
            });
        } else {
          this.loading = false;
          if ((window.location.href).includes('localhost')) {
            if ((window.location.href).includes('resto-detail')) {
              window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/" + (window.location.href).replaceAll('http://localhost:8080/', '').replaceAll('http://localhost:8081/', '')
            } else {
              window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/resto"
            }
          } else {
            if ((window.location.href).includes('resto-detail')) {
              window.location = "kam://kam5ia.com//?url=" + window.location.href
            } else {
              window.location = "kam://kam5ia.com//?url=https://m.kam5ia.com/resto"
            }
          }
          try {
            setTimeout(function () {
              if ((localStorage.getItem('is_ios', false) ?? 'null') != 'null' || (!!navigator && /iPad|iPhone|iPod/.test(navigator.userAgent))) {
                window.location = "https://apps.apple.com/id/app/kamsia/id6463642371";
              } else {
                window.location = "https://play.google.com/store/apps/details?id=com.devus.kam5ia";
              }
            }, 2000);
          } catch (error) {
            console.error("Navigation failed:", error);
          }
        }
      }
    }
  }
};

</script>

<style scoped>
.sticky-modal-component {
  z-index: 9999;
  position: fixed;
}

.image-transaction {
  height: 80px;
  width: 80px;
  border-radius: 15px;
}
</style>