<template>
  <div class="body">
    <div class="btn-cell col-12 button-back" style="padding-left: 2rem; padding-top: 2rem">
      <a v-if="!showLoginUi" href="/resto" class="btn btn-light btn-back">
        <font-awesome-icon :icon="['fas', 'chevron-left']" style="color: black"></font-awesome-icon>
      </a>
    </div>
    <div class="col-12 login-form">
      <div class="text-center">
        <h1 class="text-kamsia font-weight-bold">
          Login dan mulai eksplorasi kulinermu!
        </h1>
        <p class="text-kamsia mb-3 px-1">
          Satu langkah lagi sebelum memulai petualangan kuliner kaki lima-mu
        </p>
        <Form class="text-left" @submit="handleLogin" :validation-schema="schema" v-slot="{ errors }">
          <div class="form-group">
            <label for="email">Email</label>
            <Field type="email" class="form-control" :class="{ 'is-invalid': errors.email }" name="email" id="email" />
            <ErrorMessage name="email" class="invalid-feedback" as="div" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="input-group">
              <Field :type="this.see_confirm_password == true ? 'password' : 'text'"
                :class="{ 'is-invalid': errors.password }" class="form-control" name="password" id="password" />
              <div class="input-group-append">
                <div class="input-group-text" :class="{ 'border-danger': errors.password }">
                  <font-awesome-icon icon="fa-regular fa-eye-slash" @click="handlePassword()" />
                </div>
              </div>
              <ErrorMessage name="password" class="invalid-feedback" as="div" />
            </div>
          </div>
          <div class="float-right">
            <a href="/reset-password" class="text-small">Lupa Password?</a>
          </div>
          <button :disabled="loading" id="btn-login" class="btn btn-kamsia btn-lg btn-rounded w-100 mb-2 mt-3">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            Masuk Sekarang !
          </button>
        </Form>
        <a v-if="loginGoogle && isExistsDeviceId" href="/login-google"
          class="btn btn-icon btn-rounded icon-left btn-light btn-lg btn-block mb-2">
          <font-awesome-icon icon="fa-brands fa-google" />
          &nbsp; Masuk dengan Google
        </a>
        <a v-if="loginGoogle && !isExistsDeviceId" @click="login()"
           class="btn btn-icon btn-rounded icon-left btn-light btn-lg btn-block mb-2">
          <font-awesome-icon icon="fa-brands fa-google" />
          &nbsp; Masuk dengan Google
        </a>
        <div class="w-100 text-center mt-1">
          Belum punya akun?
          <a href="/register" class="text-kamsia">Daftar Sekarang</a>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <template v-if="PPModal">
    <div class="modal fade show d-block" ref="PPModal" tabindex="-1" role="dialog" id="PPModal">
      <div class="vertical-alignment-helper" style="padding-left: 2rem; padding-right: 2rem">
        <div class="modal-dialog vertical-align-center" role="document">
          <div class="modal-content">
            <Form class="text-left">
              <div class="modal-header">
                <div class="col-12">
                  <div class="modal-title text-center text-150rem text-kamsia">
                    Terms Conditions
                  </div>
                </div>
              </div>
              <div class="modal-body scrollable-content">
                <div class="text-dark text-1rem">Pendahuluan</div>
                <br />
                <div>
                  PT Imaji Cipta (mempunyai produk yang disebut “Kamsia”). Adalah suatu
                  perseroan terbatas yang salah satu jenis usahanya berkecimpung pada
                  bidang portal penjualan di bidang kuliner yaitu Kamsia. PT Imaji Cipta
                  dalam hal ini menyediakan Platform penjualan elektronik (e-commerce) di
                  mana Pengguna dapat melakukan transaksi jual-beli, menggunakan berbagai
                  fitur serta layanan yang tersedia. Setiap pihak yang berada pada wilayah
                  Negara Kesatuan Republik Indonesia bisa mengakses Platform Kamsia untuk
                  membuka lapangan penjualan di bidang kuliner, menggunakan layanan, atau
                  hanya sekedar mengakses / mengunjungi. <br /><br />Syarat & ketentuan
                  yang telah ditetapkan untuk mengatur pemakaian jasa yang ditawarkan oleh
                  PT. Imaji Cipta terkait penggunaan perangkat lunak Kamsia. Pengguna
                  disarankan membaca dengan seksama karena dapat berdampak pada hak dan
                  kewajiban Pengguna di bawah aturan. dengan mendaftar akun Kamsia dan
                  /atau memakai Platform Kamsia, maka Pengguna dianggap sudah membaca,
                  mengerti, tahu serta menyetujui seluruh isi pada aturan Penggunaan. Jika
                  pengguna tidak menyetujui salah satu, pesebagian, atau semua isi syarat
                  & ketentuan, maka pengguna tidak diperkenankan memakai layanan Kamsia.
                </div>
                <br />
                <div class="text-dark text-1rem">Definisi</div>
                <br />
                <div class="text-dark">
                  Dalam Aturan Penggunaan istilah-istilah di bawah ini mempunyai arti
                  sebagai berikut:
                </div>
                <br />
                <div>
                  1.) PT. Imaji Cipta (Kamsia) adalah suatu perseroan terbatas yang
                  menjalankan kegiatan usaha jasa aplikasi Kamsia, yakni aplikasi
                  pencarian lapak dalam bidang Kuliner yang dijual oleh penjual terdaftar.
                  Yang selanjutnya disebut Kamsia.
                  <br /><br />2.) Akun adalah data tentang Pengguna, minimum terdiri dari
                  nama, password, nomor telepon, dan email yang wajib diisi oleh Pengguna
                  Terdaftar. <br /><br />3.) Platform Kamsia adalah situs resmi kam5ia.com
                  dan seluruh website resmi beserta aplikasi resmi Kamsia (berbasis
                  Android dan iOS) yang dapat diakses melalui perangkat komputer dan/atau
                  perangkat seluler Pengguna. <br /><br />4.) Pembeli adalah Pengguna
                  terdaftar yang melakukan permintaan atas Makanan atau minuman yang
                  dijual oleh Penjual di Aplikasi Kamsia. <br /><br />5.) Penjual adalah
                  Pengguna terdaftar yang melakukan kegiatan buka toko dan/atau melakukan
                  penawaran atas suatu Makanan dan minuman kepada para Pengguna dan /atau
                  Pembeli. <br /><br />6.) Layanan adalah secara kolektif: (i) Platform
                  Kamsia; (ii) Konten, fitur, layanan, dan fungsi apa pun yang tersedia di
                  atau melalui Platform oleh atau atas nama Kamsia, termasuk Layanan
                  Partner; dan pemberitahuan email, tombol, widget, dan iklan.
                </div>
                <br />
                <div class="text-dark text-1rem">
                  Pengguna, Penjual, Akun, Password & Keamanan
                </div>
                <br />
                <div>
                  1.) Pengguna wajib berusia minimal 18 tahun (kecuali ditentukan lain
                  oleh peraturan perundang-undangan yang berlaku di Indonesia). Pengguna
                  yang belum genap berusia 18 tahun wajib memperoleh persetujuan dari
                  orang tua atau wali untuk menggunakan dan /atau mengakses layanan di
                  Platform Kamsia dan bertanggung jawab atas segala biaya yang timbul
                  terkait penggunaan layanan di Platform Kamsia. <br /><br />2.) Pengguna
                  harus memahami bahwa 1 (satu) nomor telepon hanya dapat digunakan untuk
                  mendaftar 1 (satu) akun Pengguna Kamsia, kecuali bagi Pengguna yang
                  telah memiliki beberapa akun dengan 1 (satu) nomor telepon sebelumnya
                  <br /><br />3.) Pengguna yang telah mendaftar berhak bertindak sebagai:
                  Pembeli dan Penjual. <br /><br />4.) Penjual diwajibkan membayar biaya
                  pembukaan toko. Penjual berhak melakukan pengaturan terhadap barang yang
                  akan diperdagangkan di lapak pribadi Penjual. <br /><br />5.) Kamsia
                  memiliki hak untuk melakukan tindakan yang perlu atas setiap dugaan
                  pelanggaran Syarat & ketentuan sesuai dengan hukum yang berlaku, yakni
                  tindakan berupa penghapusan Barang, penutupan toko, suspensi akun,
                  sampai penghapusan akun pengguna. <br /><br />6.) Pengguna menyetujui
                  untuk tidak menggunakan dan/atau mengakses sistem Kamsia secara langsung
                  atau tidak langsung, baik keseluruhan atau sebagian dengan virus,
                  perangkat lunak, atau teknologi lainnya yang dapat mengakibatkan
                  melemahkan, merusak, mengganggu dan menghambat, membatasi, mengambil
                  alih fungsionalitas serta integritas dari sistem perangkat lunak atau
                  perangkat keras, jaringan, dan/atau data pada Aplikasi Kamsia.
                  <br /><br />7.) Pengguna wajib mengetahui bahwa detail informasi berupa
                  data diri nama, alamat usaha, nomor telepon akun milik Pengguna akan
                  diterima oleh pihak Penjual dalam kemudahan bertransaksi dan berfungsi
                  sebagai database penjual sendiri <br /><br />8.) Penjual harus
                  mengetahui bahwa detail informasi milik Pengguna adalah rahasia, dan
                  karenanya Penjual tidak akan mengungkapkan detail informasi akun
                  Pengguna kepada Pihak Ketiga mana pun kecuali untuk kegiatan jual beli
                  dalam aplikasi Kamsia. <br /><br />9.) Penjual setuju untuk menanggung
                  setiap risiko terkait pengungkapan informasi Akun Pengguna kepada Pihak
                  Ketiga mana pun dan bertanggung jawab penuh atas setiap konsekuensi yang
                  berkaitan dengan hal tersebut. <br /><br />10.) Pengguna dilarang
                  menggunakan Platform Kamsia untuk melanggar peraturan yang ditetapkan
                  oleh hukum di Indonesia maupun di negara lainnya. <br /><br />11.)
                  Pengguna dilarang mendistribusikan virus atau teknologi lainnya yang
                  dapat membahayakan aplikasi Kamsia, kepentingan dan/atau properti dari
                  Pengguna lain, maupun instansi Pemerintahan. <br /><br />12.) Pengguna
                  dilarang menggunakan Platform Kamsia untuk tujuan komersial dan
                  melakukan transfer/menjual akun Pengguna ke Pengguna lain atau ke pihak
                  lain dengan tujuan apapun. <br /><br />13.) Pengguna wajib menghargai
                  hak-hak Pengguna lainnya dengan tidak memberikan informasi pribadi ke
                  pihak lain tanpa izin pihak yang bersangkutan. <br /><br />14.) Pengguna
                  wajib membaca, memahami serta mengikuti semua ketentuan yang diatur
                  dalam Aturan Penggunaan ini.
                </div>
                <br />
                <div class="text-dark text-1rem">Ketentuan Lain</div>
                <br />
                <div>
                  1.) Apabila pengguna mempergunakan fitur/layanan yang tersedia dalam
                  Website/Aplikasi Kamsia, maka Pengguna dengan ini menyatakan telah
                  memahami dan menyetujui segala syarat dan ketentuan yang diatur khusus
                  sehubungan dengan fitur/layanan yang digunakan. <br /><br />2.) Segala
                  hal yang belum dan/atau tidak diatur dalam syarat dan ketentuan khusus
                  dalam fitur tersebut maka akan sepenuhnya merujuk pada syarat dan
                  ketentuan Kamsia secara umum. <br /><br />3.) Dengan menyetujui Syarat
                  dan Ketentuan, maka Pengguna telah dianggap paham dan mengikuti
                  Kebijakan Privasi Kamsia.
                </div>
                <br />
                <div class="text-dark text-1rem">
                  Pembaruan & Perubahan Aturan Penggunaan
                </div>
                <br />
                <div>
                  Kamsia memiliki hak untuk melakukan pembaruan dan/atau perubahan Aturan
                  Penggunaan dari waktu ke waktu jika diperlukan demi keamanan dan
                  kenyamanan Pengguna di Platform Kamsia. Pengguna harus setuju untuk
                  membaca secara saksama dan memeriksa Aturan Penggunaan ini dari waktu ke
                  waktu untuk mengetahui pembaruan dan/atau perubahan apapun. Dengan tetap
                  mengakses dan menggunakan layanan Kamsia, maka pengguna dianggap
                  menyetujui perubahan-perubahan dalam Syarat & Ketentuan kami.
                </div>
              </div>
              <div class="modal-footer center-content">
                <button @click="agree()" class="btn text-white bg-kamsia">Setuju</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";
import { googleSdkLoaded } from "vue3-google-login";
export default {
  title: "Kamsia Login",
  name: "LoginComponent",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().email("Email Tidak Valid").required("Email harus diisi"),
      password: yup.string().required("Password harus diisi"),
    });

    return {
      showPassword: false,
      PPModal: false,
      loginGoogle: true,
      loading: false,
      message: "",
      schema,
      email: "",
      name: "",
      photo: "",
      password: null,
      see_confirm_password: true,
      token: null,
      showLoginUi: false,
      isExistsDeviceId: false,
    };
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === 'function';
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };
    
    const device_id = localStorage.getItem("device_id");

    this.isExistsDeviceId = device_id != 'null' && device_id != 'undefined'  && device_id != null

    if (localStorage.getItem("token") !== null) {
      const is_ios = localStorage.getItem("is_ios");
      const device_id = localStorage.getItem("device_id");
      const latitude = localStorage.getItem("lat");
      const longitude = localStorage.getItem("long");
      const getIfHasLocation = localStorage.getItem(
        "activedPermissionLocation"
      );

      localStorage.clear();
      localStorage.setItem("lat", latitude);
      localStorage.setItem("device_id", device_id);
      localStorage.setItem("long", longitude);
      localStorage.setItem("activedPermissionLocation", getIfHasLocation);

      localStorage.setItem("is_ios", is_ios);
    }
    if (localStorage.getItem("name_google") !== null) {
      this.name = localStorage.getItem("name_google");
    }
    if (localStorage.getItem("photo_google") !== null) {
      this.photo = localStorage.getItem("photo_google");
    }
    if (localStorage.getItem("email_google") !== null) {
      this.email = localStorage.getItem("email_google");
      this.handleLoginGoogle();
    }
    if (localStorage.getItem("is_ios") !== null) {
      if (localStorage.getItem("is_ios") == "true") {
        this.loginGoogle = false;
      }
    }
  },
  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        this.user = googleUser.getBasicProfile().getEmail();
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    login() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
            .initCodeClient({
              client_id:
                  "290649508281-1r3ejevgph99bq0jn0bfin92imsebrdl.apps.googleusercontent.com",
              scope: "email profile openid",
              redirect_uri: "https://jiitu.co.id/login/google",
              callback: response => {
                if (response.code) {
                  this.sendCodeToBackend(response.code);
                }
              }
            })
            .requestCode();
      });
    },
    async sendCodeToBackend(code) {
      try {
        const response = await axios.post(
            "https://oauth2.googleapis.com/token",
            {
              code,
              client_id:
                  "290649508281-1r3ejevgph99bq0jn0bfin92imsebrdl.apps.googleusercontent.com",
              client_secret: "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",
              redirect_uri: "postmessage",
              grant_type: "authorization_code"
            }
        );

        const accessToken = response.data.access_token;

        // Fetch user details using the access token
        const userResponse = await axios.get(
            "https://www.googleapis.com/oauth2/v3/userinfo",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
        );

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          let userData = userResponse.data
          let form = new FormData();
          form.append("auth_by_google", true);
          form.append("name", userData.name);
          form.append("email", userData.email);
          form.append("img", userData.picture);
          form.append("app_id", "KAM");

          axios
              .post(this.mainUrl + '/api/auth/login', form)
              .then((res) => {
                if (res["status"] == 200) {
                  // Store the user's token and other data in local storage
                  localStorage.setItem("token", res.data.token);
                  this.token = res.data.token;
                  localStorage.setItem("user", JSON.stringify(res.data.user));

                  this.PPModal = true;
                }
              })
              .catch((err) => {
                console.log(err.response.data.message);
                this.loading = false;
              });

        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
    },
    handlePassword() {
      this.see_confirm_password = !this.see_confirm_password;
    },
    async handleLoginGoogle() {
      const api_url = this.mainUrl + "/api/auth/login";
      this.loading = true;

      let form = new FormData();
      form.append("auth_by_google", true);
      form.append("name", this.name);
      form.append("email", this.email);
      form.append("img", this.photo);
      if (localStorage.getItem("device_id") !== null) {
        form.append("device_id", localStorage.getItem("device_id"));
      }
      form.append("app_id", 'KAM');

      axios
        .post(api_url, form)
        .then((res) => {
          if (res["status"] == 200) {
            // Store the user's token and other data in local storage
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            this.token = res.data.token;

            this.PPModal = true;
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          this.loading = false;
        });
    },
    handleLogin(user, actions) {
      const api_url = this.mainUrl + "/api/auth/login";
      this.loading = true;

      let form = new FormData();
      form.append("email", user.email);
      form.append("password", user.password);
      if (localStorage.getItem("device_id") !== null) {
        form.append("device_id", localStorage.getItem("device_id"));
      }
      form.append("app_id", 'KAM');

      axios
        .post(api_url, form)
        .then((res) => {
          if (res["status"] == 200) {
            // Store the user's token and other data in local storage
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            this.token = res.data.token;

            this.PPModal = true;
            this.showLoginUi = true;
          }
        })
        .catch((err) => {
          actions.setFieldError("email", err.response.data.message);
          this.loading = false;
        });
    },
    agree() {
      localStorage.setItem("name_google", "");
      localStorage.setItem("email_google", "");
      localStorage.setItem("photo_google", "");
      this.PPModal = false;
      this.$store.commit('clearPageResto');
      
      if (this.$router.options.history.state.back == "/transaction") {
        this.$router.replace("/transaction/?page=fromLogin");
      } else if (this.$route.query.page !== undefined) {
        if (localStorage.getItem("table") != null) {
          axios
            .get(
              this.mainUrl +
              "/api/kamsia/v2/table/outlet?table=" +
              localStorage.getItem("table"),
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((res) => {
              if (this.$route.query.page !== "/resto-detail/" + res.data.outlet_id) {
                res.data.has_outlet === true ? this.$router.replace("/group/" + res.data.group_id) : this.$router.replace(this.$route.query.page + '/?page=fromLogin');
              } else {
                res.data.has_outlet === true ? this.$router.replace("/group/" + res.data.group_id) : this.$router.replace("/resto-detail/" + res.data.outlet_id + '/?page=fromLogin');
              }
            })
            .catch((err) => {
              console.log(err);
              this.$router.replace("/resto");
            });
        } else {
          this.$router.replace(this.$route.query.page + '/?page=fromLogin');
        }
      } else {
        this.$router.replace("/resto");
      }
      //
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem !important;
  font-weight: 500;
}

.main-wrapper {
  background-color: #f9b93d;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
}

.main-content {
  width: 100% !important;
}

#btn-login {
  background-color: #604226;
  color: white !important;
  border-radius: 25px;
}

.btn.btn-lg {
  padding: 0.6rem 1.5rem;
  font-size: 14px;
  font-weight: 500;
}

.btn-rounded {
  border-radius: 20px;
}

.btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 0.3rem 0.8rem;
  letter-spacing: 0.5px;
}

.input-rounded {
  border-radius: 20px;
  padding: 0.6rem 1.5rem;
}

.text-kamsia {
  color: #604226;
}

.btn-password {
  background-color: #fdfdff;
  border-color: #e4e6fc;
}

.main-wrapper {
  background-color: #fbb143;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}

.button-back {
  position: fixed;
  top: 0;
  left: 0;
}

.body {
  background-color: #fbb143;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.login-form {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
