<template>
  <div id="app">
    <div class="main-wrapper bg-kamsia">
      <div class="main-content">
        <div class="col-12 col-md-6 col-lg-6 offset-md-3 offset-lg-3 p-0">
          <div
            class="container container-welcome text-center"
            style="padding-left: 1.5rem; padding-right: 1.5rem"
          >
            <h1 class="text-kamsia-dark font-weight-bold text-150rem">Sign Up Account</h1>
            <Form
              class="text-left"
              @submit="handleRegister"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="form-group">
                <label for="username">Name</label>
                <Field
                  id="username"
                  name="username"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.username }"
                />
                <ErrorMessage name="username" class="invalid-feedback" as="div" />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                />
                <ErrorMessage name="email" class="invalid-feedback" as="div" />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <Field
                    id="password"
                    name="password"
                    :type="this.see_password == true ? 'password' : 'text'"
                    class="form-control"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      :class="{ 'border-danger': errors.password }"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-eye-slash"
                        @click="handlePassword()"
                      />
                    </div>
                  </div>
                  <ErrorMessage name="password" class="invalid-feedback" as="div" />
                </div>
              </div>
              <div class="form-group">
                <label for="confpassword">Confirm Password</label>
                <div class="input-group">
                  <Field
                    id="confpassword"
                    name="confpassword"
                    :type="this.see_confirm_password == true ? 'password' : 'text'"
                    class="form-control"
                    :class="{ 'is-invalid': errors.confpassword }"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      :class="{ 'border-danger': errors.password }"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-eye-slash"
                        @click="handlePassword(true)"
                      />
                    </div>
                  </div>
                  <ErrorMessage name="confpassword" class="invalid-feedback" as="div" />
                </div>
              </div>
              <button
                :disabled="loading"
                id="btn-register"
                class="btn btn-lg btn-kamsia-dark text-white btn-rounded w-100 mb-2 mt-3"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                Sign Up
              </button>
            </Form>
            <div class="text-center mt-1 text-dark">
              have an account?
              <a href="/login" class="text-kamsia-dark">Log In</a>
            </div>
            <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default {
  title: "Kamsia Register",
  name: "RegisterComponent",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username harus diisi"),
      email: yup.string().email("Email Tidak Valid").required("Email harus diisi"),
      password: yup.string().required("Password harus diisi"),
      confpassword: yup.string().required("Confirm Password harus diisi"),
    });

    return {
      loading: false,
      message: "",
      schema,
      showPassword: false,
      see_password: true,
      see_confirm_password: true,
    };
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === 'function';
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    handlePassword(isConfirmationPassword = false) {
      return isConfirmationPassword
        ? (this.see_confirm_password = !this.see_confirm_password)
        : (this.see_password = !this.see_password);
    },
    handleRegister(user, actions) {
      const api_url = this.mainUrl + "/api/auth/register";
      this.loading = true;

      if (user.password != user.confpassword) {
        actions.setFieldError("password", "Password tidak sama dengan Confirm Password");
        this.loading = false;
      } else {
        let form = new FormData();
        form.append("name", user.username);
        form.append("email", user.email);
        form.append("password", user.password);
        if (localStorage.getItem("device_id") !== null) {
          form.append("device_id", localStorage.getItem("device_id"));
        }
        form.append("app_id", 'KAM');

        axios
          .post(api_url, form)
          .then((res) => {
            console.log(res);
            if (res["status"] == 200) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.user));

              this.$router.replace("/resto");
            }
          })
          .catch((err) => {
            console.log(err);
            actions.setFieldError("email", err.response.data.errors.email);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

.main-content {
  width: 100% !important;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
@/services/form
